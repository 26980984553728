import React from "react"

import Layout from "../components/Layout";
import Main from "../components/sections/Main";
import AboutMe from '../components/sections/AboutMe';
import Portfolio from '../components/sections/Portfolio'

const IndexPage = () => (
  <Layout>
    <Main/>
    {/* <AboutMe/>
    <Portfolio/> */}
    
   
  </Layout>
)

export default IndexPage
