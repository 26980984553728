import React from 'react'
import { css } from "@emotion/core"
import Particles from 'react-particles-js'


const Particle = () => {
    return (
        <Particles params={{
            "particles": {
                "number": {
                  "value": 80,
                  "density": {
                    "enable": true,
                    "value_area": 800
                  }
                },
                "color": {
                  "value": "#000000"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 2,
                    "color": "#0f0d0d"
                  },
                  "polygon": {
                    "nb_sides": 8
                  },
                  "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                  }
                },
                "opacity": {
                  "value": 0.20042650760819036,
                  "random": false,
                  "anim": {
                    "enable": true,
                    "speed": 0.4,
                    "opacity_min": 0.2,
                    "sync": false
                  }
                },
                "size": {
                  "value": 20.042650760819036,
                  "random": true,
                  "anim": {
                    "enable": true,
                    "speed": 46.288401101176675,
                    "size_min": 0.1,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": true,
                  "distance": 192.40944730386272,
                  "color": "#ffffff",
                  "opacity": 0.2,
                  "width": 1.9
                },
                "move": {
                  "enable": true,
                  "speed": 1,
                  "direction": "none",
                  "random": false,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "repulse"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "push"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 400,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 1.5
                  },
                  "repulse": {
                    "distance": 200,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
              "retina_detect": true
        }}
        css={css` 
        position:absolute;
        height:100vh;
        width:100%;;
        margin:0;
        padding:0;
       `}
       
        >
             
         </Particles>
    )
}

export default Particle;