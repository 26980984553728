import React from "react"
import { css } from "@emotion/core"
import Particle from "../utils/Particle"
import ImgMain from "../utils/ImgMain"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Main = () => {
  return (
    <main
      css={css`
        height: 100vh;
        display: flex;
      `}
    >

      <div
        className="text"
        id="particles-js"
        css={css`
          position: relative;
          background-color: #bdbdbd;
          width: 100vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (min-width: 768px) {
           
          }
        `}
      >
        <h1
          css={css`
            width: 90vw;
            margin: 0 auto;
            font-size: 5em;
            letter-spacing: 0.2rem;
            line-height: 1;
            z-index: 5000;
            @media (min-width: 768px) {
              width: auto;
              font-size: 6em;
              margin-bottom: 0;
              padding-bottom: 0;
              letter-spacing: 0.2rem;
              line-height: 1;
              z-index: 5000;
            }
          `}
        >
          Miguel Atencia
          <span
            css={css`
              display: block;
              font-weight: lighter;
              font-size: 1.6rem;
              text-align: right;
              background-color: #f3f3f3;
              padding: 0.3rem 0.5rem;
              letter-spacing: 0.1rem;
              border-radius: 0.5rem;
            `}
          >
            Web Developer
          </span>
        </h1>

        <Particle />

        <div className="redes" css={css`
          margin-top:2rem;
          z-index:1253;
        `}>
          <Link
            to="https://www.facebook.com/MiguelAtenciaC"
            target="_blank"
            className="icon-main"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCBkUPzh_s5zYPmEgascgmiA?view_as=subscriber"
            target="_blank"
            className="icon-main"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "youtube"]} />
          </Link>
          <Link
            to="https://www.instagram.com/miguelatenciac/?hl=en"
            target="_blank"
            className="icon-main"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </Link>
          <Link
            to="https://github.com/matenciac14"
            target="_blank"
            className="icon-main"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "github"]} />
          </Link>
          <Link
            to="https://www.linkedin.com/in/miguel-atencia-canoles-4b787884/"
            target="_blank"
            className="icon-main"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </Link>
        </div>
      </div>
    </main>
  )
}

export default Main
